import Head from "next/head";

import Protected from "@components/layout/Protected";
import DashboardPage from "@components/app/DashboardPage";

function Index() {
  return (
    <Protected>
      <Head>
        <title>Jurahilfe.de</title>
      </Head>
      <DashboardPage />
    </Protected>
  );
}

export default Index;
